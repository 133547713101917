<template>
  <PageHeader image="header-4" withMobile :title="$lang('title')" :breadcrumbs="$lang('breadcr')" />
  <!-- section 1 -->
  <section class="py-8 bg-blue4">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-5 mb-5 mb-lg-0">
          <h2 class="fw-bold text-blue2 mb-4">
            <span class="text-green1">{{ $lang("section1.title1") }}</span
            >{{ $lang("section1.title2") }}
          </h2>
          <p class="fw-bold">{{ $lang("section1.text1") }}</p>
          <p>{{ $lang("section1.text2") }}</p>
          <p>{{ $lang("section1.text3") }}</p>
        </div>
        <div class="col-lg-6 offset-lg-1">
          <ImageContainer fileName="nedcollege_1" />
        </div>
      </div>
    </div>
  </section>
  <!-- section2 -->
  <section class="pt-8 pb-10 bg-green2">
    <div class="container">
      <div class="text-center">
        <h2 class="text-blue2 mx-3 mb-4">{{ $lang("section2.title") }}</h2>
        <p class="text-white">{{ $lang("section2.text") }}</p>

        <img src="/imgs/svg/map_w_flags.svg" class="mt-n6 w-100 flags-map-gradient" />
      </div>
    </div>
  </section>
  <!-- section3 -->
  <section class="floatting-section">
    <div class="container">
      <div class="card card-white">
        <div class="card-body text-center">
          <h3 class="text-blue1">
            {{ $lang("section3.title1") }}
          </h3>
          <h3 class="text-green1 mb-4">
            {{ $lang("section3.title2") }}
          </h3>

          <button class="btn btn-blue-grad-2" @click="goTo('contact_us')">
            {{ $lang("section3.button") }}
          </button>
        </div>
      </div>
    </div>
  </section>
  <!-- section4 -->
  <section class="pb-8 pt-10 bg-blue-grad-1">
    <div class="container text-center">
      <h2 class="text-greed3">{{ $lang("section4.title") }}</h2>

      <div class="my-6 d-grid grid-1-cols grid-md-2-cols gap-4">
        <div class="d-flex align-items-center gap-4 text-left">
          <div class="flex-shrink-0 badge-icon badge-lg badge-green3 fw-bold">+10</div>
          <p class="m-0">{{ $lang("section4.text1") }}</p>
        </div>
        <div class="d-flex align-items-center gap-4 text-left">
          <div class="flex-shrink-0 badge-icon badge-lg badge-blue3">
            <img src="/imgs/svg/icon_chat.svg" alt="" class="icon" />
          </div>
          <p class="m-0">{{ $lang("section4.text2") }}</p>
        </div>
        <div class="d-flex align-items-center gap-4 text-left">
          <div class="flex-shrink-0 badge-icon badge-lg badge-blue2 fw-bold">12.000</div>
          <p class="m-0">{{ $lang("section4.text3") }}</p>
        </div>
        <div class="d-flex align-items-center gap-4 text-left">
          <div class="flex-shrink-0 badge-icon badge-lg badge-blue2">
            <img src="/imgs/svg/icon_world.svg" alt="" class="icon" />
          </div>
          <p class="m-0">{{ $lang("section4.text4") }}</p>
        </div>
        <div class="d-flex align-items-center gap-4 text-left">
          <div class="flex-shrink-0 badge-icon badge-lg badge-blue3">
            <img src="/imgs/svg/icon_buildings.svg" alt="" class="icon" />
          </div>
          <p class="m-0">{{ $lang("section4.text5") }}</p>
        </div>
        <div class="d-flex align-items-center gap-4 text-left">
          <div class="flex-shrink-0 badge-icon badge-lg badge-green3">
            <img src="/imgs/svg/icon_hat.svg" alt="" class="icon" />
          </div>
          <p class="m-0">{{ $lang("section4.text6") }}</p>
        </div>
      </div>

      <button class="btn btn-green-grad px-5" @click="goTo('contact_us')">
        {{ $lang("section4.button") }}
      </button>
    </div>
  </section>
  <!-- section5 -->
  <section class="py-8 bg-blue4">
    <div class="container">
      <div class="row">
        <div
          class="col-lg-5 offset-lg-1 order-lg-1 d-flex flex-column justify-content-center mb-6 mb-lg-0"
        >
          <h2 class="text-blue2 mb-4">
            {{ $lang("section5.title") }}
          </h2>
          <p class="text-black2">{{ $lang("section5.text") }}</p>
        </div>
        <div class="col-lg-6">
          <div class="nedcollege-image-w-logo">
            <ImageContainer fileName="nedcollege_2" />
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- section6 -->
  <section class="row">
    <div class="col-md-6 bg-green2 with-fbutton">
      <div class="container half-container-md half-container-left py-8">
        <h2 class="text-white mb-5">{{ $lang("section6.title1") }}</h2>
        <ImageContainer fileName="campus_dublin_3" class="mb-5 mr-md-5" />

        <p class="text-white mb-5">
          {{ $lang("section6.text1") }}
        </p>
        <button class="btn btn-blue-grad-1" @click="openTab('campus_dublin')">
          {{ $lang("section6.button1") }}
        </button>
      </div>
      <div class="floatting-button floatting-button-md-right caption-bubble">
        <h3 class="fw-bold m-0">
          {{ $lang("section6.circle1") }}
          <span class="text-blue2">{{ $lang("section6.circle2") }}</span>
        </h3>
      </div>
    </div>
    <div class="col-md-6 bg-blue3 py-8">
      <div class="container half-container-md half-container-right text-md-right">
        <h2 class="text-white mb-5">
          {{ $lang("section6.title2") }}
        </h2>
        <ImageContainer fileName="campus_limerick_2" class="mb-5 ml-md-5" />

        <p class="text-white mb-5">
          {{ $lang("section6.text2") }}
        </p>
        <button class="btn btn-blue-grad-1" @click="openTab('campus_limerick')">
          {{ $lang("section6.button2") }}
        </button>
      </div>
    </div>
  </section>
  <!-- section7 -->
  <section class="pt-8 pb-10 pb-lg-9 bg-blue4">
    <div class="container">
      <div class="row">
        <div class="col-12 mb-5">
          <h2 class="text-blue2">{{ $lang("section7.title") }}</h2>
          <h3 class="fw-regular">
            {{ $lang("section7.subtitle") }}
          </h3>
        </div>
        <div class="col-lg-4">
          <h3 class="text-green1 mb-4">{{ $lang("section7.subtitle1") }}</h3>
          <p>{{ $lang("section7.text1") }}</p>
          <p>{{ $lang("section7.text2") }}</p>
        </div>
        <div class="col-lg-4">
          <h3 class="text-green1 mb-4">{{ $lang("section7.subtitle2") }}</h3>
          <p>{{ $lang("section7.text3") }}</p>
          <p>{{ $lang("section7.text4") }}</p>
        </div>
        <div class="col-lg-4">
          <ImageContainer fileName="ned_mission_logo" />
        </div>
        <div class="col-12 mt-5">
          <h3 class="text-blue2 mb-4">{{ $lang("section7.subtitle3") }}</h3>

          <ul class="ml-5">
            <li>{{ $lang("section7.list1") }}</li>
            <li>{{ $lang("section7.list2") }}</li>
            <li>{{ $lang("section7.list3") }}</li>
            <li>{{ $lang("section7.list4") }}</li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  <!-- section8 -->
  <section class="floatting-section">
    <div class="container">
      <div class="d-grid grid-lg-2-cols bg-white b-radius-md shadow">
        <ImageContainer fileName="card_header_6" background size="180px" />
        <div class="p-4 p-md-5">
          <p>
            <strong class="text-blue2">{{ $lang("section8.text1") }}</strong>
            {{ $lang("section8.text2") }}
          </p>
        </div>
      </div>
    </div>
  </section>

  <TestimonialSection class="pt-10 pt-lg-9">
    <template #title>
      <h2 class="fw-bold text-green3 mb-5">
        {{ $lang("testimonial.title") }}
      </h2>
    </template>
  </TestimonialSection>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import TestimonialSection from "@/components/TestimonialSection";

export default {
  components: {
    PageHeader,
    TestimonialSection,
  },
};
</script>

<style></style>
